<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions"></div>
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="申请人">
              <a-input v-decorator="['userName']" placeholder="请输入申请人姓名"></a-input>
            </a-form-item>
            <a-form-item label="手机号">
              <a-input v-decorator="['phone']" placeholder="请输入手机号"></a-input>
            </a-form-item>
            <a-form-item label="审核状态">
              <a-select
                class="form-control sm"
                v-decorator="['bverify']"
                placeholder="请选择"
                allowClear
                :options="typesMap.bverify"
              ></a-select>
            </a-form-item>
            <a-form-item label="审核时间">
              <a-range-picker v-decorator="['time']" />
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :expandIconColumnIndex="1"
            :pagination="false"
            size="middle"
            @change="tableChange"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal
      :title="modal.title"
      v-model="modal.visible"
      @ok="handleOk"
      class="modal-scroll"
      :footer="modal.type === 'AUDIT' ? undefined : null"
      :cancelButtonProps="{
        on: {
          click: handleBtnCancel,
        },
      }"
      cancelText="驳回"
      :width="800"
    >
      <div style="min-width: 700px" v-if="modal.data.photoCategory">
        <div class="grid grid-cols-3">
          <div>申请人（班组）{{ modal.data.userName }}</div>
          <div>电话 {{ modal.data.phone }}</div>
          <div>身份证 {{ modal.data.cardNumber }}</div>
        </div>
        <a-tabs>
          <a-tab-pane key="1" tab="个人资料">
            <div ref="grGallery">
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem :src="modal.data.photoCategory.GR.YYLS[0]" title="半年银行流水"></GalleryItem>
                <GalleryItem :src="modal.data.photoCategory.GR.DKZM[0]" title="银行存款证明"></GalleryItem>
                <GalleryItem :src="modal.data.photoCategory.GR.ZXBG[0]" title="征信报告"></GalleryItem>
              </div>
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem :src="modal.data.photoCategory.GR.SFU[0]" title="身份证正面"></GalleryItem>
                <GalleryItem :src="modal.data.photoCategory.GR.SFD[0]" title="身份证反面"></GalleryItem>
              </div>
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem :src="modal.data.photoCategory.GR.JHZ[0]" title="结婚证"></GalleryItem>
              </div>
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem
                  :src="item"
                  :title="`房产证${index + 1}`"
                  v-for="(item, index) in modal.data.photoCategory.GR.FCZ"
                  :key="index"
                ></GalleryItem>
              </div>
              <div class="grid grid-cols-3">
                <GalleryItem
                  :src="item"
                  :title="`行驶证${index + 1}`"
                  v-for="(item, index) in modal.data.photoCategory.GR.XSZ"
                  :key="index"
                ></GalleryItem>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="企业资料" forceRender>
            <div ref="qyGallery">
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem :src="modal.data.photoCategory.QY.YYZZ[0]" title="营业执照"></GalleryItem>
              </div>
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem :src="modal.data.photoCategory.QY.RWM[0]" title="法人身份证正面"></GalleryItem>
                <GalleryItem :src="modal.data.photoCategory.QY.GHM[0]" title="法人身份证反面"></GalleryItem>
              </div>
              <div class="mb-5">
                <div class="text-gray-800">基本存款账户</div>
                <div class="info-row">
                  <div class="info-row-label">开户行：</div>
                  <div>{{ modal.data.openingBank }}</div>
                </div>
                <div class="info-row">
                  <div class="info-row-label">账户：</div>
                  <div>{{ modal.data.bankAccount }}</div>
                </div>
                <div class="info-row">
                  <div class="info-row-label">账户名称：</div>
                  <div>{{ modal.data.accountTitle }}</div>
                </div>
                <div class="info-row">
                  <div class="info-row-label">申请单位全称：</div>
                  <div>{{ modal.data.fullName }}</div>
                </div>
                <div class="info-row">
                  <div class="info-row-label">企业所在地：</div>
                  <div>{{ modal.data.address }}</div>
                </div>
              </div>
              <div class="grid grid-cols-3 mb-5">
                <GalleryItem :src="modal.data.photoCategory.QY.KHXK[0]" title="银行开户许可证"></GalleryItem>
                <GalleryItem :src="modal.data.photoCategory.QY.AQXK[0]" title="安全生产许可证"></GalleryItem>
                <GalleryItem :src="modal.data.photoCategory.QY.SCZZ[0]" title="安全生产资质"></GalleryItem>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgRotate from 'lightgallery/plugins/rotate'
import GalleryItem from './modules/GalleryItem.vue'

export default {
  mixins: [queryViewMixin],
  components: { GalleryItem },
  data() {
    const tableColumns = [
      {
        title: '申请人',
        width: '10%',
        align: 'center',
        dataIndex: 'userName',
      },
      {
        title: '手机',
        width: '15%',
        align: 'center',
        dataIndex: 'phone',
      },
      {
        title: '申请时间',
        width: '20%',
        align: 'center',
        dataIndex: 'createTime',
        sorter: true,
        sortOrder: 'descend',
      },
      {
        title: '审核状态',
        width: '10%',
        align: 'center',
        dataIndex: 'bverify',
        customRender: (text, row, index) => {
          return <div>{this.translateType(text, 'bverify')}</div>
        },
      },
      {
        title: '审核人',
        width: '10%',
        align: 'center',
        dataIndex: 'auditAdminName',
      },
      {
        title: '审核时间',
        width: '20%',
        align: 'center',
        dataIndex: 'auditTime',
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              {row.bverify === '1' && (
                <span>
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.audit(row, 'DETAIL')
                    }}
                  >
                    查看
                  </a>
                </span>
              )}
              {row.bverify === '2' && (
                <span>
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.audit(row, 'AUDIT')
                    }}
                  >
                    审核
                  </a>
                </span>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        state: [
          { value: 1, label: '启用' },
          { value: 0, label: '停用' },
        ],
        bverify: [
          { value: 1, label: '通过' },
          { value: 2, label: '未审核' },
          { value: 0, label: '驳回' },
        ],
      }
    },
  },
  methods: {
    getSortParams() {
      const sortColumn = this.tableColumns.find(column => column.sortOrder)
      let sortField = ''
      let sortOrder = ''
      if (sortColumn) {
        sortField = sortColumn.dataIndex
        sortOrder = sortColumn.sortOrder || ''
      }
      return {
        sortField,
        sortOrder,
      }
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/ums/buser/listPage', {
          ...this.formatTimeRangeParams(this.getPageParams(_pageNo, _pageSize), 'time'),
          ...this.getSortParams(),
        })
        const list = res.data.data
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async audit(row, type) {
      this.resetModal()
      const bizId = 'buserId'
      const serverRes = await this.$axios.post('/ums/buser/getPhotosByBuserId', {
        [bizId]: row[bizId],
      })
      this.modal.visible = true
      this.modal.type = type
      this.modal.title = type === 'AUDIT' ? '审核' : '查看'
      const photos = serverRes.data || []
      const photoCategory = {
        GR: {
          // 半年银行流水
          YYLS: [],
          // 银行存款证明
          DKZM: [],
          // 征信报告
          ZXBG: [],
          // 身份证正面
          SFU: [],
          // 身份证反面
          SFD: [],
          // 结婚证
          JHZ: [],
          // 名下房产证
          FCZ: [],
          // 名下行驶证
          XSZ: [],
        },
        QY: {
          // 营业执照（正本或副本）
          YYZZ: [],
          // 法人身份证人物面
          RWM: [],
          // 法人身份证国徽面
          GHM: [],
          // 银行开户许可证
          KHXK: [],
          // 安全生产许可证
          AQXK: [],
          // 安全生产资质
          SCZZ: [],
        },
      }
      photos.map(item => {
        const src = item.picUrl
        if (photoCategory[item.category][item.type]) {
          photoCategory[item.category][item.type].push(src)
        }
      })
      this.modal.data = { ...row, photoCategory }
      this.$nextTick(() => {
        const galleryInstances = [this.$refs.grGallery, this.$refs.qyGallery]
        galleryInstances.map(gallery => {
          lightGallery(gallery, {
            plugins: [lgZoom, lgThumbnail, lgRotate],
            selector: '.gallery-item',
          })
        })
      })
    },
    getThumbnail(src) {
      return `${src}?x-oss-process=image/resize,w_144`
    },
    async handleOk() {
      const actionUrl = '/ums/buser/updateAudit'
      await this.$axios.post(actionUrl, {
        id: this.modal.data.id,
        bverify: 1,
      })
      this.$message.success('操作成功!')
      this.resetModal()
      this.doQuery()
    },
    async handleBtnCancel() {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认要驳回?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      try {
        const actionUrl = '/ums/buser/updateAudit'
        await this.$axios.post(actionUrl, {
          id: this.modal.data.id,
          bverify: 0,
        })
        this.$message.success('操作成功!')
        this.resetModal()
        this.doQuery()
      } catch (e) {
        console.error(e)
      }
    },
  },
  mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped>
@import '~lightgallery/css/lightgallery.css';
@import '~lightgallery/css/lg-thumbnail.css';
@import '~lightgallery/css/lg-zoom.css';
@import '~lightgallery/css/lg-rotate.css';
.info-row {
  display: flex;
  .info-row-label {
    width: 7em;
    text-align: right;
  }
}
</style>
