<template>
  <div>
    <div class="text-gray-800">{{ title }}</div>
    <a class="gallery-item" :data-src="src" :data-sub-html="title" v-if="getType(src) === 'image'">
      <div class="gallery-image-container">
        <auto-img :src="getThumbnail(src)" />
      </div>
    </a>
    <a v-if="getType(src) === 'pdf'">
      <a>
        {{ getName(src) }}
        <span class="whitespace-nowrap">
          <a class="ml-2" @click="doPDFPreview(src)">
            <a-icon type="eye"></a-icon>
          </a>
          <a class="ml-2" :href="src" download>
            <a-icon type="download"></a-icon>
          </a>
        </span>
      </a>
    </a>
    <a v-if="getType(src) === 'word'">
      <a>
        {{ getName(src) }}
        <a class="ml-2" @click="doWORDPreview(src)">
          <a-icon type="eye"></a-icon>
        </a>
        <a class="ml-2" :href="src" download>
          <a-icon type="download"></a-icon>
        </a>
      </a>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
  },
  methods: {
    getThumbnail(src) {
      return `${src}?x-oss-process=image/resize,w_144`
    },
    getType(src) {
      let suffix = ''
      let fileType = ''
      try {
        const flieArr = src.split('.')
        suffix = flieArr[flieArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      const imageList = ['png', 'jpg', 'jpeg']
      if (imageList.includes(suffix)) {
        fileType = 'image'
      }
      const pdfList = ['pdf']
      if (pdfList.includes(suffix)) {
        fileType = 'pdf'
      }
      const wordList = ['doc', 'docx']
      if (wordList.includes(suffix)) {
        fileType = 'word'
      }
      return fileType
    },
    getName(src) {
      return src.replace(/(.*\/)*([^.]+)/gi, '$2')
    },
    doPDFPreview(src) {
      window.open(`/libs/pdfjs/web/viewer.html?file=${src}`)
    },
    doWORDPreview(src) {
      // http://view.xdocin.com/xdoc?src=
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${src}`)
    },
  },
}
</script>
<style lang="less" scoped>
.gallery-image-container {
  height: 144px;
  width: 144px;
}
</style>
